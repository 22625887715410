.headerParagraph {
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Sansation_Light', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  }
  
a:active {
color: '#C5C4FD';
}

a:visited {
color: '#3E3BF7';
}

a {
color: '#3E3BF7';
}
  