.container {
  background-color: #8381FA;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name { 
  color: white;
  font-size: 60px;
  margin-top: 50px;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Sansation', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.horizontal {
  color: #FFFFFF;
  background-color: #FFFFFF;
  height: .2;
  border-color : #FFFFFF;
  width: 695px;
}

.portrait {
  max-width: 200px;
  max-height: 200px;
  border-radius: 100px;
  margin-top: 30px;
  margin-bottom: 15px;
}

.paragraphContainer {
  width: 75%;
  max-width: 600px;
  margin: auto;
}

.contactWrapper {
  display: flex;
  flex-direction: row;
}


@media only screen and (max-width: 700px) {
  .contactWrapper {
    flex-direction: column;
    align-items: center;
  }

  .horizontal {
    width: 100px;
  }

  .name {
    font-size: 24px;
    margin-top: 50px;
  }
}

.contactInfo {
  color: white;
  margin: 4px;
  margin-top: 10px;
}

.contactInfo a {
  text-decoration: none;
  text-align: center;
  color: white;
  padding: 6px;
  background-color: rgba(168, 167, 255, 0.74);
  display: block;
  width: 200px;
}

.contactInfo a:hover {
  background-color: rgba(168, 167, 255, 0.3);
}

@font-face {
  font-family: 'Sansation_Light';
  src: local('Sansation_Light'), url(./fonts/Sansation_Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Sansation';
  src: local('Sansation'), url(./fonts/Sansation_Regular.ttf) format('truetype');
}


